<template>
    <v-container
            id="regular-tables"
            fluid
            tag="section"
    >
        <v-row>
            <v-col >
            <v-btn class="ma-2" color="success" @click="tambah">
                <v-icon left>mdi-plus</v-icon> Tambah Penjualan
            </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col
                    cols="12"
                    lg="12"
            >
                <base-material-card
                        class="px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Daftar Penjualan
                        </div>

                        <div class="subtitle-1 font-weight-light">
                        </div>
                    </template>
                    <v-row>
                        <v-col cols="12"
                               lg="6">
                            <v-text-field
                                    v-model="search"
                                    label="Search"
                                    single-line

                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <div class="py-1"/>
                    <v-data-table
                            :headers="headers"
                            :items="items"
                            :search="search"
                    >
                        <template v-slot:item.total_amount="{ item }">
                            <input type="hidden" v-model.lazy="item.total_amount" v-money="money" /> {{item.total_amount}}
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-icon class="mx-1" @click="edit(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                    color="error"
                                    class="mx-1"
                                    @click="modalHapus(item)"
                            >
                                mdi-close
                            </v-icon>
                        </template>

                    </v-data-table>
                    <v-dialog v-model="dialogDelete" persistent max-width="290">
                        <v-card>
                            <v-card-title class="headline">Hapus Penjualan</v-card-title>
                            <v-card-text>
                                Apa anda yakin ingin menghapus data ini?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" text @click="dialogDelete = false">Tidak</v-btn>
                                <v-btn color="green darken-1" text @click="hapus()">Ya</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </base-material-card>
            </v-col>

            <v-dialog v-model="dialog" persistent max-width="290">
                <v-card>
                    <v-card-text class="text-center">
                        Loading
                        <v-spacer></v-spacer>
                        <v-progress-linear
                                indeterminate
                                rounded
                                color="yellow darken-2"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar
                    v-model="snackbar"
                    :color="color"
                    :top="true"
            >
                <span>{{message}}</span>
                <v-btn
                        dark
                        icon
                        @click="snackbar = false"
                >
                    <v-icon>
                        $vuetify.icons.cancel
                    </v-icon>
                </v-btn>
            </v-snackbar>

        </v-row>
        <div class="py-3"/>


    </v-container>
</template>
<script>
    import {ApiUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'
    import {VMoney} from 'v-money'
    export default {
        name: 'IndexGoodsReceipt',
        data() {
            return {
                snackbar: false,
                color: 'success',
                message: '',
                dialog: false,
                dialogDelete: false,
                itemDelete: null,
                namaPelanggan: '',
                namaBarang: '',
                deskripsiBarang: '',
                idBarang: '',
                search: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: 'Rp ',
                    suffix: '',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                },
                rules: {
                    required: value => !!value || 'Required.',
                },
                headers: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Tanggal',
                        filterable: true,
                        value: 'date',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Nama Pelanggan',
                        filterable: true,
                        value: 'customer_name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Total Harga',
                        filterable: true,
                        value: 'total_amount',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                items: [],
            }
        },
        directives: {
            money: VMoney
        },
        computed: {
            ...mapState(['currentUser']),
        },
        mounted: function () {
            this.ambilData()
        },
        methods: {
            edit: function (item) {
                console.log('edit',item)
                this.$router.push({path: '/sales/edit/'+item.id})
            },
            modalHapus: function (item) {
                this.dialogDelete = true
                this.itemDelete = item
            },
            hapus: function () {
                this.dialogDelete = false
                let id = this.itemDelete.id
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'delete', url: ApiUrl + 'order/hapus/' + id, headers: header})
                    .then(response => {
                        console.log(response)

                        if (response.data.success) {
                            this.snackbar = true
                            this.color = 'success'
                            this.message = 'Berhasil hapus data                    '
                            this.ambilData()
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        alert(error)
                    })
            },
            ambilData: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'order', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.items = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            tambah: function(e){
                this.$router.push({path: '/sales/tambah'})
            }
        }
    }
</script>